.homeContainer {
    text-align: center;
}

.contactContainer {
    text-align: center;
}

.aboutContainer {
    text-align: center;
}

.helpContainer {
    margin: 5rem 0 5rem;
}

#oougHeader {
    text-align: center;
    font-family: Arial Black;
    margin-top: 12rem;
    font-size: 38.5px; //TODO: change this to rem
}
.layout {
    &__nav {
        display: block;
    }
    &__container {
        max-width: 750px;
    }
    &__inner {

    }
}
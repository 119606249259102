.generalFAQs {
    &__generalCollapseOne {
        height: 0px;
    }
    &__warning {
        color: red;
    }
    &__sectionTitle {
        text-align: center;
    }
}
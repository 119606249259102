// import colors and use button and disabled button colors

.signUpContainer {
    line-height: 2.5rem;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    &__head {
        text-align: center;
    }
    &__signup-form {
        input {
            border-radius: 4px;
            width: 206px;
            height: 2rem;
            font-size: .85rem;
        }
        &_email {
            margin-right: 4rem;
        }
        &_alias {
            margin-right: 4rem;
        }
        &_password {
        }
        &_submit {
            margin-top: .5rem;
            padding: 4px 12px;
            border: 1px solid #cccccc;
            border-radius: 4px;
            background-image: linear-gradient(to bottom, #0088cc, #0044cc);
            color: #fff;
        }
    }
}
.header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    height: 50px;
    margin-bottom: 0;
    z-index: 1030;
    overflow: visible;
    background-color: #1b1b1b;
    border-color: #252525;
    background-image: linear-gradient(to bottom, #222222, #111111);
    background-repeat: repeat-x;
    height: 41px;
    &__nav {
        display: block;
    }
    &__container {
        max-width: 750px;
    }
    &__inner {

    }
    ul {
        list-style-type: none;
        li {
            float: left;
            line-height: 20px;
            a {
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                color: #999999;
                float: none;
                padding: 10px 15px 10px;
                display: block;
                text-decoration: none;
                &:hover {
                    color: white;
                    background-color: transparent;
                    outline: 0;
                }
            }
        }
    }
    &__left {
        position: relative;
        display: block;
        float: left;
        left: 0;
        margin: 0 10px 0 0;
    }
    &__right {
        float: right;
        margin: 0 10px 0 0;
    }
    &__dropdown {
        color: #999;
    }
}